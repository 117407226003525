<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-chip
        v-for="(revision, idx) in revisions"
        removable
        outline square
        :key="idx"
        :color="revision.color"
        text-color="white"
        icon="memory"
        :selected.sync="revision.selected"
        :label="revision.revName"
        :title="revision.revName"
        v-model="revision.check"
        @remove="state => remove(state, process)"
        @update:selected="state => selected(state, revision, idx)"
      >
      </q-chip>
      <q-chip
        clickable
        color="orange"
        text-color="white"
        icon="add"
        label="버전추가"
        title="버전추가"
        @click="addProcess"
      />
    </div>
    <div class="col-12">
      <template>
        <component
          :is="component"
          :param="param"
          :selectedRow.sync="selectedRow"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'revision',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      selectedRow: {
        seq: 0,
        revName: '', 
        revContent: '',
        revUserName: '',
        plantNm: '',
        revYmd: '',
      },
      revisions: [],
      component: null,
      count: 1,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.component = () => import('./revisionInfo.vue');
      this.getList();
    },
    getList() {
      this.revisions = [
        {
          seq: 1,
          revName: '버전1', 
          revContent: '개정개정개정개정',
          revUserName: '',
          plantNm: '1사업장',
          revYmd: '2021-09-22',
          check: true, selected: false, color: 'grey-6'
        },
      ]
      this.selected(true, this.revisions[0], 0)
    },
    remove(state, revision) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGEXCEPT', // 제외하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          revision.check = false
          this.revisions = this.$_.reject(this.revisions, revision)
        },
        // 취소 callback 함수
        cancelCallback: () => {
          revision.check = true
        },
      });
    },
    selected(state, revision, idx) {
      if (state) {
        this.revisions.forEach((item, index) => {
          if (idx !== index) {
            item.selected = false;
            item.color = 'grey-6'
            this.selectedRow = revision;
          } else {
            item.selected = true;
            item.color = 'primary'
          }
        })
      } else {
        this.$_.forEach(this.revisions, item => {
          item.selected = false;
          item.color = 'grey-6'
        })
      }
    },
    addProcess() {
      this.revisions.push({
        revName: '버전' + ++this.count,
        revYmd: '',
        revContent: '',
        revUserName: '',
        plantNm: "",
        deptNm: "",
        check: true, selected: false, color: 'grey-6'
      })
    },
  }
};
</script>
